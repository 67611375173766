import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">
Uniswap V1, while still fully functional, is no longer under active development. Looking for <Link to='/docs/v2/' mdxType="Link">V2 documentation</Link>?
    </Info>
    <p>{`In Uniswap, there is a separate exchange contract for each ERC20 token. These exchanges hold reserves of both ETH and their associated ERC20. Instead of waiting to be matched in an order-book, users can make trades against the reserves at any time. Reserves are pooled between a decentralized network of liquidity providers who collect fees on every trade.`}</p>
    <p>{`Pricing is automatic, based on the `}<inlineCode parentName="p">{`x * y = k`}</inlineCode>{` market making formula which automatically adjusts prices based off the relative sizes of the two reserves and the size of the incoming trade. Since all tokens share ETH as a common pair, it is used as an intermediary asset for direct trading between any ERC20 ⇄ ERC20 pair.`}</p>
    <h2 {...{
      "id": "eth-⇄-erc20-calculations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eth-%E2%87%84-erc20-calculations",
        "aria-label": "eth ⇄ erc20 calculations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ETH ⇄ ERC20 Calculations`}</h2>
    <p>{`The variables needed to determine price when trading between ETH and ERC20 tokens is:`}</p>
    <ul>
      <li parentName="ul">{`ETH reserve size of the ERC20 exchange`}</li>
      <li parentName="ul">{`ERC20 reserve size of the ERC20 exchange`}</li>
      <li parentName="ul">{`Amount sold `}{`(`}{`input`}{`)`}{` or amount bought `}{`(`}{`output`}{`)`}</li>
    </ul>
    <h3 {...{
      "id": "amount-bought-sell-order",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#amount-bought-sell-order",
        "aria-label": "amount bought sell order permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amount Bought `}{`(`}{`sell order`}{`)`}</h3>
    <p>{`For sell orders `}{`(`}{`exact input`}{`)`}{`, the amount bought `}{`(`}{`output`}{`)`}{` is calculated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Sell ETH for ERC20
const inputAmount = userInputEthValue
const inputReserve = web3.eth.getBalance(exchangeAddress)
const outputReserve = tokenContract.methods.balanceOf(exchangeAddress).call()

// Sell ERC20 for ETH
const inputAmount = userInputTokenValue
const inputReserve = tokenContract.methods.balanceOf(exchangeAddress).call()
const outputReserve = web3.eth.getBalance(exchangeAddress)

// Output amount bought
const numerator = inputAmount * outputReserve * 997
const denominator = inputReserve * 1000 + inputAmount * 997
const outputAmount = numerator / denominator
`}</code></pre>
    <h3 {...{
      "id": "amount-sold-buy-order",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#amount-sold-buy-order",
        "aria-label": "amount sold buy order permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amount Sold `}{`(`}{`buy order`}{`)`}</h3>
    <p>{`For buy orders `}{`(`}{`exact output`}{`)`}{`, the cost `}{`(`}{`input`}{`)`}{` is calculated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Buy ERC20 with ETH
const outputAmount = userInputTokenValue
const inputReserve = web3.eth.getBalance(exchangeAddress)
const outputReserve = tokenContract.methods.balanceOf(exchangeAddress).call()

// Buy ETH with ERC20
const outputAmount = userInputEthValue
const inputReserve = tokenContract.methods.balanceOf(exchangeAddress).call()
const outputReserve = web3.eth.getBalance(exchangeAddress)

// Cost
const numerator = outputAmount * inputReserve * 1000
const denominator = (outputReserve - outputAmount) * 997
const inputAmount = numerator / denominator + 1
`}</code></pre>
    <h3 {...{
      "id": "liquidity-provider-fee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#liquidity-provider-fee",
        "aria-label": "liquidity provider fee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Provider Fee`}</h3>
    <p>{`There is a 0.3% liquidity provider fee built into the price formula. This can be calculated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`fee = inputAmount * 0.003
`}</code></pre>
    <h3 {...{
      "id": "exchange-rate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#exchange-rate",
        "aria-label": "exchange rate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exchange Rate`}</h3>
    <p>{`The exchange rate is simply the output amount divided by the input amount.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const rate = outputAmount / inputAmount
`}</code></pre>
    <h2 {...{
      "id": "erc20-⇄-erc20-calculations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#erc20-%E2%87%84-erc20-calculations",
        "aria-label": "erc20 ⇄ erc20 calculations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ERC20 ⇄ ERC20 Calculations`}</h2>
    <p>{`The variables needed to determine price when trading between two ERC20 tokens is:`}</p>
    <ul>
      <li parentName="ul">{`ETH reserve size of the input ERC20 exchange`}</li>
      <li parentName="ul">{`ERC20 reserve size of the input ERC20 exchange`}</li>
      <li parentName="ul">{`ETH reserve size of the output ERC20 exchange`}</li>
      <li parentName="ul">{`ERC20 reserve size of the output ERC20 exchange`}</li>
      <li parentName="ul">{`Amount sold `}{`(`}{`input`}{`)`}{` or amount bought `}{`(`}{`output`}{`)`}</li>
    </ul>
    <h3 {...{
      "id": "amount-bought-sell-order-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#amount-bought-sell-order-1",
        "aria-label": "amount bought sell order 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amount Bought `}{`(`}{`sell order`}{`)`}</h3>
    <p>{`For sell orders `}{`(`}{`exact input`}{`)`}{`, the amount bought `}{`(`}{`output`}{`)`}{` is calculated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// TokenA (ERC20) to ETH conversion
const inputAmountA = userInputTokenAValue
const inputReserveA = tokenContractA.methods.balanceOf(exchangeAddressA).call()
const outputReserveA = web3.eth.getBalance(exchangeAddressA)

const numeratorA = inputAmountA * outputReserveA * 997
const denominatorA = inputReserveA * 1000 + inputAmountA * 997
const outputAmountA = numeratorA / denominatorA

// ETH to TokenB conversion
const inputAmountB = outputAmountA
const inputReserveB = web3.eth.getBalance(exchangeAddressB)
const outputReserveB = tokenContract.methods.balanceOf(exchangeAddressB).call()

const numeratorB = inputAmountB * outputReserveB * 997
const denominatorB = inputReserveB * 1000 + inputAmountB * 997
const outputAmountB = numeratorB / denominatorB
`}</code></pre>
    <h3 {...{
      "id": "amount-sold-buy-order-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#amount-sold-buy-order-1",
        "aria-label": "amount sold buy order 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amount Sold `}{`(`}{`buy order`}{`)`}</h3>
    <p>{`For buy orders `}{`(`}{`exact output`}{`)`}{`, the cost `}{`(`}{`input`}{`)`}{` is calculated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Buy TokenB with ETH
const outputAmountB = userInputEthValue
const inputReserveB = web3.eth.getBalance(exchangeAddressB)
const outputReserveB = tokenContractB.methods.balanceOf(exchangeAddressB).call()

// Cost
const numeratorB = outputAmountB * inputReserveB * 1000
const denominatorB = (outputReserveB - outputAmountB) * 997
const inputAmountB = numeratorB / denominatorB + 1

// Buy ETH with TokenA
const outputAmountA = userInputEthValue
const inputReserveA = tokenContractA.methods.balanceOf(exchangeAddressA).call()
const outputReserveA = web3.eth.getBalance(exchangeAddressA)

// Cost
const numeratorA = outputAmountA * inputReserveA * 1000
const denominatorA = (outputReserveA - outputAmountA) * 997
const inputAmountA = numeratorA / denominatorA + 1
`}</code></pre>
    <h3 {...{
      "id": "liquidity-provider-fee-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#liquidity-provider-fee-1",
        "aria-label": "liquidity provider fee 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Provider Fee`}</h3>
    <p>{`There is a 0.30% liquidity provider fee to swap from TokenA to ETH on the input exchange. There is another 0.3% liquidity provider fee to swap the remaining ETH to TokenB.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const exchangeAFee = inputAmountA * 0.003
const exchangeBFee = inputAmountB * 0.003
`}</code></pre>
    <p>{`Since users only inputs Token A, it can be represented to them as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const combinedFee = inputAmountA * 0.00591
`}</code></pre>
    <h3 {...{
      "id": "exchange-rate-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#exchange-rate-1",
        "aria-label": "exchange rate 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exchange Rate`}</h3>
    <p>{`The exchange rate is simply the output amount divided by the input amount.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const rate = outputAmountB / inputAmountA
`}</code></pre>
    <h2 {...{
      "id": "deadlines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deadlines",
        "aria-label": "deadlines permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deadlines`}</h2>
    <p>{`Many Uniswap functions include a transaction `}<inlineCode parentName="p">{`deadline`}</inlineCode>{` that sets a time after which a transaction can no longer be executed. This limits miners holding signed transactions for extended durations and executing them based off market movements. It also reduces uncertainty around transactions that take a long time to execute due to issues with gas price.`}</p>
    <p>{`Deadlines are calculated by adding the desired amount of time `}{`(`}{`in seconds`}{`)`}{` to the latest Ethereum block timestamp.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`web3.eth.getBlock('latest', (error, block) => {
  deadline = block.timestamp + 300 // transaction expires in 300 seconds (5 minutes)
})
`}</code></pre>
    <h2 {...{
      "id": "recipients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#recipients",
        "aria-label": "recipients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recipients`}</h2>
    <p>{`Uniswap allows traders to swap tokens and transfer the output to a new `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` address. This allows for a type of payment where the payer sends one token and the payee receives another.`}</p>
    <h2 {...{
      "id": "eth-⇄-erc20-trades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eth-%E2%87%84-erc20-trades",
        "aria-label": "eth ⇄ erc20 trades permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ETH ⇄ ERC20 Trades`}</h2>
    <p>{`Coming soon…`}</p>
    <h2 {...{
      "id": "erc20-⇄-erc20-trades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#erc20-%E2%87%84-erc20-trades",
        "aria-label": "erc20 ⇄ erc20 trades permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ERC20 ⇄ ERC20 Trades`}</h2>
    <p>{`Coming soon…`}</p>
    <h2 {...{
      "id": "custom-pools",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#custom-pools",
        "aria-label": "custom pools permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Custom Pools`}</h2>
    <p>{`Coming soon…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      